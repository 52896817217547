/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import AppContextProvider from './src/context/appContext'

// Wrap whole app with custom React Context
export const wrapRootElement = ({ element }) => <AppContextProvider>{element}</AppContextProvider>

// Service worker has been updated in the background and the page is ready to reload to apply changes
export const onServiceWorkerUpdateReady = () => {
  // Set window.___swUpdated to prevent update on page navigation.
  // We would update app manually.
  // Overrides https://github.com/gatsbyjs/gatsby/blob/8938c953003e2fb488c2ae72f2eb966d0db16833/packages/gatsby/cache-dir/navigation.js#L68
  window.___swUpdated = false
}

export const onServiceWorkerUpdateFound = () => {
  console.log(
    `There is a new app update, it will be downloaded in the background and you will be notified when it's done.`
  )
}

export const onServiceWorkerInstalled = () => {
  console.log(`App is now available offline!`)
}

// Add previous url location to location state
// Source: https://stackoverflow.com/a/58524372
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
}

// Load IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
    console.log('# IntersectionObserver is polyfilled!')
  }
}
