import { createTheme } from '@material-ui/core/styles'

// Helper to transform PX to REM
function pxToRem(value) {
  return `${value / 16}rem`
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    common: {
      black: '#212121'
    },
    primary: {
      main: '#429AFA', // blue
      light: '#55A9FC', // blue
      highlight: '#70B8FC', // lighter blue
      contrastText: '#fff'
    },
    secondary: {
      main: '#fff',
      light: '#E4F3FE', // light blue
      contastText: '#212121'
    },
    error: {
      main: '#FF3C38' // red
    },
    warning: {
      main: '#FFAC3B' // orange
    },
    success: {
      main: '#1FA389' // green
    },
    grey: {
      100: '#f7f8f8',
      500: '#9E9E9E',
      700: '#666666'
    },
    background: {
      default: '#F2F2F2'
    },
    text: {
      primary: '#212121'
    },
    yellow: {
      primary: '#EFCB68'
    },
    orange: {
      primary: '#D7907B'
    },
    purple: {
      primary: '#655A7C'
    },
    black: {
      primary: '#605F5E'
    },
    map: {
      active: '#12A2DF',
      inactive: '#BEBEBE'
    }
  },
  shadows: [
    'none',
    '0px 3px 14px -6px rgba(0, 0, 0, 0.12)',
    '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '0px 3px 14px rgba(0, 0, 0, 0.12)'
  ],
  typography: {
    h1: {
      fontSize: pxToRem(22),
      '@media (min-width:600px)': {
        fontSize: pxToRem(24)
      },
      fontWeight: 400
    },
    h2: {
      fontSize: pxToRem(20),
      '@media (min-width:600px)': {
        fontSize: pxToRem(22)
      },
      fontWeight: 400
    },
    h3: {
      fontSize: pxToRem(18),
      '@media (min-width:600px)': {
        fontSize: pxToRem(20)
      },
      fontWeight: 400
    },
    h4: {
      fontSize: pxToRem(16),
      '@media (min-width:600px)': {
        fontSize: pxToRem(18)
      },
      fontWeight: 400
    },
    h5: {
      fontSize: pxToRem(14),
      '@media (min-width:600px)': {
        fontSize: pxToRem(16)
      },
      fontWeight: 500
    },
    h6: {
      fontSize: pxToRem(14),
      '@media (min-width:600px)': {
        fontSize: pxToRem(16)
      },
      fontWeight: 500
    },
    subtitle1: {
      fontSize: pxToRem(12),
      fontWeight: 500
    },
    body1: {
      fontSize: pxToRem(14),
      '@media (min-width:600px)': {
        fontSize: pxToRem(16)
      },
      fontWeight: 400
    },
    body2: {
      fontSize: pxToRem(13),
      '@media (min-width:600px)': {
        fontSize: pxToRem(14)
      },
      fontWeight: 400
    },
    button: {
      fontSize: pxToRem(12),
      '@media (min-width:600px)': {
        fontSize: pxToRem(14)
      },
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: pxToRem(10),
      '@media (min-width:600px)': {
        fontSize: pxToRem(12)
      },
      fontWeight: 400,
      lineHeight: 1.4
    },
    overline: {
      fontSize: pxToRem(10),
      '@media (min-width:600px)': {
        fontSize: pxToRem(12)
      },
      fontWeight: 400,
      textTransform: 'uppercase'
    }
  },
  overrides: {
    MuiChip: {
      root: {
        height: 28
      },
      outlined: {
        border: '1px solid #666'
      },
      icon: {
        fontSize: pxToRem(20)
      }
    }
  },
  // Change default components props
  props: {
    MuiAppBar: {
      elevation: 1
    },
    MuiSnackbarContent: {
      elevation: 1
    }
  }
})

export default theme
