module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/path1/src/containers/app-layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UN SanctionsApp","short_name":"UN SanctionsApp","start_url":"/","background_color":"#FFFFFF","theme_color":"#429AFA","display":"standalone","icon":"src/assets/icons/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05f3edb6336783d28ca746084661b685"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164059248-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/vercel/path1/custom-sw-code.js","precachePages":["/types-of-sanctions","/types-of-sanctions/*","/cases","/cases/*","/cases/*/episodes/*","/facts/*","/facts","/pages","/pages/*","/checklist","/checklist/*","/checklist/**/*","/search","/analogy-finder","/404"],"workboxConfig":{"skipWaiting":false,"cleanupOutdatedCaches":true,"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
