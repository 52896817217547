import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

// Custom MUI theme
import theme from '../shared/global.js'

// Global styles
import '../assets/styles/global.scss'

function LayoutContainer(props) {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      {props.children}
    </ThemeProvider>
  )
}

export default LayoutContainer
